<template>
  <Toast />
  <div class="grid">
    <div class="col-12 md:col-12">
      <div class="card">
        <h5>
          <strong>DETALLE DE USUARIO</strong>
        </h5>
        <div class="grid">
          <div class="col-5 flex align-items-center justify-content-center">
            <div class="p-fluid">
              <div class="field">
                <label for="password">Contraseña Anterior</label>
                <InputText
                  id="password"
                  v-model="user.password"
                  type="password"
                />
                <small class="p-invalid" v-if="errors.password">{{
                  errors.password[0]
                }}</small>
              </div>
              <div class="field">
                <label for="newpassword">Nueva Contraseña</label>
                <InputText
                  id="newpassword"
                  v-model="newpassword"
                  type="newpassword"
                />
                <small class="p-invalid" v-if="errors.newpassword">{{
                  errors.newpassword[0]
                }}</small>
              </div>
              <Button
                icon="pi pi-save"
                label="ACTUALIZAR CONTRASEÑA"
                @click="actualizarPassword"
              />
            </div>
          </div>
          <div class="col-2">
            <Divider layout="vertical">
              <b>PERFIL</b>
            </Divider>
          </div>
          <div class="col-5 align-items-center justify-content-center">
            <div class="field">
              <p>
                <strong>ID USUARIO:</strong> <span>{{ user.id }}</span>
              </p>
              <p>
                <strong>NOMBRE COMPLETO:</strong> <span>{{ user.name }}</span>
              </p>
            </div>
            <div class="field">
              <p>
                <strong>CORREO ELECTR&Oacute;NICO: </strong>
                <span>{{ user.email }}</span>
              </p>
            </div>
            <div class="field">
              <p>
                <strong>FECHA REGISTRO: </strong>
                <span>{{ formatFecha(user.created_at) }}</span>
              </p>
            </div>
            <div class="field">
              <p>
                <strong>FECHA ACTUALIZACI&Oacute;N: </strong>
                <span> {{ formatFecha(user.updated_at) }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h4 class="text-center"><strong>PERMISOS ASIGNADOS</strong></h4>
        <PermissionsFrom :permisos-seleccionados="user.permissions" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAuth } from "@/stores";
import PermissionsFrom from "@/module/roles/PermissionsForm.vue";
import UserService from "@/service/UserService";

export default {
  components: {
    PermissionsFrom,
  },
  data() {
    return {
      user: {},
      newpassword: "",
      errors: {},
    };
  },
  userService: null,
  authService: null,
  created() {
    this.authService = useAuth();
    this.userService = new UserService();
  },
  mounted() {
    this.user = { ...this.authService.user };
    this.userService.ListarPermisosUsuarios().then((data) => {
      this.user.permissions = data.permisos;
    });
  },
  methods: {
    actualizarPassword() {
      let datos = {
        id: this.user.id,
        password: this.user.password,
        newpassword: this.newpassword,
      };
      this.userService.updatedUser(datos).then((data) => {
        if (Object.prototype.hasOwnProperty.call(data, "errors")) {
          this.errors = data.errors;
        } else {
          this.errors = {};
          if (data.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: data.mensaje,
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Exito",
              detail: "Contraseña actualizada correctamente",
              life: 3000,
            });
            return this.authService.userLogout();
          }
        }
      });
    },
    formatFecha(fecha) {
      return new Date(fecha).toLocaleString();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>