<template>
  <div>
   <!--  <h3>
      Permisos :
      <template v-if="editar">
        <Checkbox
          id="checkOption1"
          name="option"
          value="Chicago"
          v-tooltip="'Marcar todos'"
          v-show="editar"
        />
        <label for="checkOption1" class="text-base"> Seleccionar todos</label>
      </template>
    </h3> -->
    <template v-if="cargando">
      <div>Se estan buscando los permisos...</div>
    </template>
    <template v-else>
      <div class="row" v-for="(modulo, key) in permisos" :key="key">
        <div class="grid border-bottom-2 mb-2 border-500 mt-2 pb-1">
          <div class="md:col-2">
            <h4 class="mb-0">
              <!-- <Checkbox
                id="checkOption1"
                name="option"
                value="Chicago"
                v-tooltip.top="'Marcar Lista'"
                v-show="editar"
              /> -->
              {{ key }}
            </h4>
          </div>
          <div class="col-12 md:col-2" v-for="(permiso, i) in modulo" :key="i">
            <div class="field-checkbox mb-0 border">
              <template v-if="editar">
                <Checkbox
                  id="{{'checkOption' + i}}"
                  name="permisos"
                  :value="permiso.name"
                  v-model="permisosAsigandosNombre"
                />
                <!-- v-model="checkboxValue" -->
              </template>
              <template v-else>
                <i
                  v-if="verificarPermisoAsignado(permiso.id)"
                  class="pi pi-check"
                ></i>
                <i v-else class="pi pi-times"></i>
                <!-- X -->
              </template>
              <label for="checkOption1">{{ quitarModelo(permiso.name) }}</label>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import RolService from "@/service/RolService";
export default {
  emits: ["enviarPermisos"],
  props: {
    editarComponente: {
      type: Boolean,
      default: false,
    },
    permisosSeleccionados: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      cargando: false,
      permisos: {},
      editar: this.editarComponente,
      permisosAsignados: this.permisosSeleccionados,
      permisosAsigandosNombre: [],
    };
  },
  rolService: null,
  created() {
    this.rolService = new RolService();
  },
  mounted() {
    this.cargarPermisos();
  },
  methods: {
    verificarPermisoAsignado(permiso_id) {
      return Object.prototype.hasOwnProperty.call(
        this.permisosAsignados,
        permiso_id
      );
    },
    cargarPermisos() {
      this.rolService
        .obtenerPermisosPorAgrupadosModulos()
        .then((data) => {
          this.cargando = false;
          this.permisos = data.permisos;
          this.permisosAsigandosNombre = this.obtenernombrearray(
            this.permisosAsignados
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    quitarModelo(nombre) {
      let tmp = nombre.split(" ");
      return tmp[tmp.length - 1];
    },
    obtenernombrearray(elementos) {
      let tmp = [];
      Object.keys(elementos).forEach((key) => {
        tmp.push(elementos[key].name);
      });

      return tmp;
    },
  },
  watch: {
    permisosSeleccionados(val) {
      this.permisosAsignados = val;
    },
    permisosAsigandosNombre() {
      this.$emit("enviarPermisos", this.permisosAsigandosNombre);
    },
  },
};
</script>
